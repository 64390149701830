import { css } from 'lit';
import { CSSResultArray, LitElement, TemplateResult, html } from 'lit';
import bind from 'bind-decorator';
import { customElement, property, query, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin';
import { ifDefined } from 'lit/directives/if-defined.js';
import ConfigCARSx from '../../config/ConfigCARSx';
import './notification-banner';
import scss from '../../utils/scssAsCss';
import bbuttonbaseScss from '../css/lib/bbuttonbase.scss';
import sharedScss from '../css/shared.scss';
import resetScss from '../css/lib/reset.scss';
import store, { ResponsiveIBrowser, RootState } from '../../redux/redux-store';
import { AppSection, Breakpoint, MobileBreakpoint } from '../../constants';
import containsTargetPenetrate from '../../utils/contains-penetrate';
import { navigate } from '../../redux/redux-routing';
import { selectShowReadOnly } from '../../redux/redux-user';
import { userHasPermission } from '../../redux/user-permissions';
import './nav-menu-item';
import { UserPermissions } from '../../../typings/api';
import { PageConfig } from '../../config/ConfigCARSx';
import { ConfigUserManagement } from '../../config/ConfigLogin';

/**
 * @returns {boolean} whether the menu hide operation should be blocked
 */
function menuHideBlocker(): boolean {
	return window.innerWidth < MobileBreakpoint;
}

@customElement('page-nav')
export default class extends connect(store)(LitElement) {
	@property({ type: String, reflect: true }) public page?: AppSection;

	@state() private mediaType?: ResponsiveIBrowser['mediaType'];

	@state() private showReadOnly?: boolean;

	@query('#menu-container') private menuContainer?: HTMLDetailsElement;

	@state() private username = '';

	@state() private roles: string[] = [];

	public static override get styles(): CSSResultArray {
		return [
			resetScss,
			bbuttonbaseScss,
			sharedScss,
			css`
notification-banner {
  position: fixed;
  top: var(--header-height);
  width: 100%;
}

:host {
  z-index: 2526;
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgb(var(--brand-primary));
}
@media print {
  :host {
    display: none;
  }
}

.signout-menu::part(bg) {
  background-color: rgb(var(--brand-primary-dark));
}

.signout-menu::part(img) {
  width: 36px;
  height: 36px;
}

.signout-menu::part(label) {
  color: rgb(var(--brand-secondary));
}

.signout-menu::part(caret) {
  fill: rgb(var(--brand-secondary));
}

#topbar {
  z-index: 1024;
  height: 84px;
  left: 0px;
  color: rgb(var(--brand-grey-dimmer));
  background-color: rgb(var(--brand-primary));
  background-image: var(--bg-image);
  background-size: var(--bg-size);
  border-bottom: 1px solid rgba(var(--brand-grey-dimmer), 0.75);
}
@media (max-width: 999px) {
  #topbar {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 70px;
  }
}
#page-title {
  flex: 1 1 100%;
  width: 100%;
  line-height: 70px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  color: rgb(var(--brand-secondary));
  display: none;
}
@media (max-width: 999px) {
  #page-title {
    display: block;
  }
}

#logo-link {
  height: 100%;
  float: left;
}
@media (max-width: 999px) {
  #logo-link {
    flex: 0 0 auto;
    float: none;
  }
}
#logo-link a {
  display: block;
  padding: calc(var(--ggutter) / 2) var(--ggutter);
}
@media (max-width: 999px) {
  #logo-link a {
    padding: 12px;
  }
}
#logo-link a img {
  object-fit: fill;
}
@media (max-width: 999px) {
  #logo-link a img {
    height: 48px;
    max-height: 48px;
  }
}

#menu-container {
  width: 100%;
}
@media (max-width: 999px) {
  #menu-container {
    flex: 0 0 auto;
    width: auto;
    float: none;
  }
}
#menu-container > summary {
  position: absolute;
  z-index: 1050;
  top: 18px;
  right: 18px;
  display: none;
  width: 36px;
  height: 36px;
  background: url("/img/icon-hamburger-fill.svg") no-repeat center center;
  background-size: 36px;
}
@media (max-width: 999px) {
  #menu-container > summary {
    display: block;
    position: static;
    padding: 0px 18px;
    height: 100%;
  }
}
#menu-container > summary > span {
  display: none;
}
#menu-container[open] > summary {
  background-image: url("/img/icon-hamburger-fill - gold.svg");
}
#menu-container > .details-content {
  z-index: 1050;
  height: inherit;
}
#menu-container > .details-content ul {
  height: 84px;
}
#menu-container > .details-content ul li {
  height: 100%;
}
#menu-container > .details-content ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0px var(--ggutter);
  text-decoration: inherit;
  color: inherit;
}
#menu-container > .details-content ul li a > div {
  flex: 0 0 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-top: calc(var(--ggutter) * 0.75);
  border-radius: 50%;
  background-color: rgb(var(--brand-grey-dim));
}
#menu-container > .details-content ul li a > div img {
  display: block;
}
#menu-container > .details-content ul li a span {
  flex: 0 1 100%;
  margin-top: 4px;
}
#menu-container > .details-content ul li.active a {
  background-color: rgba(var(--brand-primary-darkest), 0.5);
  color: rgb(var(--brand-secondary));
}
#menu-container > .details-content ul li.active a > div {
  background-color: rgb(var(--brand-secondary));
}
#menu-container > .details-content ul.left-links {
  float: left;
}
#menu-container > .details-content ul.left-links li {
  float: left;
  margin-left: var(--ggutter);
}
#menu-container > .details-content ul.right-links {
  float: right;
}
#menu-container > .details-content ul.right-links li {
  float: right;
  /* margin-right: calc(var(--ggutter) * 2); */
  position: relative;
}
#signout-link {
  position: relative;
}
#signout-link > div {
  background-color: rgb(var(--brand-primary-dark)) !important;
  border-radius: 50% !important;
  padding: 0px !important;
}
#signout-link > span {
  color: rgb(var(--brand-secondary));
}

#read-only-tag {
  display: block;
  width: calc(var(--topbar-height) + 1.625rem);
  transform: rotate(90deg) translateX(-50%) translateX(-1rem);
  position: absolute;
  left: calc(var(--ggutter) * -4 - 0.5rem);
  text-transform: uppercase;
  font-size: 0.7em;
  text-align: center;
  color: rgb(var(--brand-primary));
  background: rgb(var(--brand-secondary));
}
@media (max-width: 999px) {
  #read-only-tag {
    display: none;
  }
}

#read-only-tag-mobile {
  display: none;
  flex: 1 0 auto;
  align-self: center;
  height: var(--ggutter);
  width: calc(var(--topbar-height));
  transform: rotate(90deg);
  text-transform: uppercase;
  font-size: 0.7em;
  align-items: center;
  justify-content: center;
  color: rgb(var(--brand-primary));
  background: rgb(var(--brand-secondary));
  margin-right: calc(-2 * var(--ggutter));
  margin-left: calc(-2 * var(--ggutter) - 1ch);
}
@media (max-width: 999px) {
  #read-only-tag-mobile {
    display: flex;
  }
}

@media (max-width: 999px) {
  #menu-container > .details-content {
    position: absolute;
    z-index: 10;
    top: 70px;
    left: 0px;
    width: 100%;
    background-color: rgb(var(--brand-primary));
  }
  #menu-container > .details-content ul {
    height: auto;
  }
  #menu-container > .details-content ul.left-links, #menu-container > .details-content ul.right-links {
    float: none;
    clear: both;
  }
  #menu-container > .details-content ul.left-links li, #menu-container > .details-content ul.right-links li {
    display: block;
    width: 100%;
    float: none;
    clear: both;
    margin: 0px;
    text-align: unset;
  }
  #menu-container > .details-content ul.left-links li a, #menu-container > .details-content ul.right-links li a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px var(--ggutter) var(--ggutter);
  }
  #menu-container > .details-content ul.left-links li a > div > img, #menu-container > .details-content ul.right-links li a > div > img {
    display: inline-block;
  }
  #menu-container > .details-content ul.left-links li a span, #menu-container > .details-content ul.right-links li a span {
    margin-top: 10px;
    display: inline-block;
    text-indent: 1em;
  }
}
`,
		];
	}

	closeMobile(): void {
		if (this.menuContainer && this.mediaType === Breakpoint.mobile) {
			this.menuContainer.open = false;
		}
	}

	@bind
	private renderMenuItemToTemplate(item: PageConfig): TemplateResult {
		if (
			(item.permission !== undefined && userHasPermission(item.permission) === false) ||
			item.active === false ||
			item.renderNavbar === false
		) {
			return html`<!-- user does not have access to this menu item -->`;
		}
		return html`<li>
			<nav-menu-item
				?active=${this.page === item.pageID}
				.subpages=${item.subpages ?? []}
				icon=${item.icon}
				href=${ifDefined(item.route)}
				label=${item.pageTitle}
				@linkClick=${this.onLinkClicked}
				@closeNav=${this.closeMobile}
			></nav-menu-item>
		</li>`;
	}

	private onLinkClicked(e: CustomEvent<string>): void {
		void store.dispatch(navigate(e.detail));
	}

	@bind
	private renderSignout() {
		const showManageUsersPage =
			ConfigUserManagement.showManageUsersPage === true &&
			userHasPermission(UserPermissions.USER_MANAGEMENT_ACCESS) === true;
		const subpages: Partial<PageConfig>[] = [
			{
				pageTitle: this.roles.join(', '),
			},
		];
		if (showManageUsersPage) {
			subpages.push({
				pageID: 'user-groups',
				pageTitle: ConfigCARSx.Pages[AppSection.USER_GROUPS].pageTitle,
				route: ConfigCARSx.Pages[AppSection.USER_GROUPS].route,
			});
		}
		subpages.push({
			pageID: 'signout',
			pageTitle: 'Sign Out',
			route: `/logout`,
		});
		return html`
			<nav-menu-item
				class="signout-menu"
				?active=${false}
				.subpages=${subpages}
				icon="img/icon-profile-fill-solid.svg"
				label=${this.username}
				linksRight="true"
				@closeNav=${this.closeMobile}
			></nav-menu-item>
			${this.showReadOnly ? html`<span id="read-only-tag">View Only</span>` : undefined}
		`;
	}

	public override render(): TemplateResult {
		return html`
			<header id="topbar">
				<h1 id="logo-link">
					<a href="${ConfigCARSx.Logo.href}" title="Home"
						><img src="${ConfigCARSx.Logo.icon}" alt="${ConfigCARSx.Logo.alt}" height="64"
					/></a>
				</h1>
				<h2 id="page-title">
					${this.page ? ConfigCARSx.Pages[this.page].pageTitle : html`<!-- missing page id -->`}
				</h2>
				${this.showReadOnly ? html`<span id="read-only-tag-mobile">View Only</span>` : undefined}
				<details id="menu-container" ?open=${this.mediaType === Breakpoint.desktop}>
					<summary><span>Menu</span></summary>
					<nav class="details-content">
						<ul class="left-links">
							${Object.values(ConfigCARSx.Pages).map(this.renderMenuItemToTemplate)}
						</ul>
						<ul class="right-links">
							<li>${this.renderSignout()}</li>
						</ul>
					</nav>
				</details>
			</header>
			<notification-banner> </notification-banner>
		`;
	}

	public override stateChanged(state: RootState): void {
		this.page = state.routing.page ?? undefined;
		this.mediaType = state.responsive?.mediaType;
		this.showReadOnly = selectShowReadOnly(state);
		this.username = state.user?.authority?.name ?? '';
		this.roles =
			(state.user?.authority?.userGroups?.length
				? state.user?.authority?.userGroups
				: state.user?.authority?.roles) ?? [];
	}

	@bind
	private onGlobalMouseup(e: MouseEvent): void {
		if (
			menuHideBlocker() &&
			this.menuContainer?.open === true &&
			!containsTargetPenetrate(this.menuContainer, e)
		) {
			this.menuContainer.open = false;
			this.menuContainer.blur();
		}
	}

	public override connectedCallback(): void {
		window.addEventListener('mouseup', this.onGlobalMouseup);
		super.connectedCallback();
	}

	public override disconnectedCallback(): void {
		window.removeEventListener('mouseup', this.onGlobalMouseup);
		super.disconnectedCallback();
	}
}
