import { css } from 'lit';
import { CameraDto } from '../../typings/api';
import { MILLISECONDS_PER_SECOND } from '../constants';

export const USER_CAN_TOGGLE_CAMERA_FEED = true;

export const cctvColumns = <const>['type', 'cameraNumber', 'description', 'name', 'status'];

export const activeCctvColumns: typeof cctvColumns[number][] = [
	'type',
	'cameraNumber',
	'description',
	'name',
	...(USER_CAN_TOGGLE_CAMERA_FEED ? ['status'] : []),
] as typeof cctvColumns[number][];

export const ConfigCCTV = {
	Grids: [1, 4, 9, 16], //  controls which grid layout options are listed for monitors

	//  camera thumbnails will be constructed thus: [CamThumbRoot]+[Camera.name]+[CamThumbExt]
	CamThumbRoot: 'http://content.trafficwise.org/cctv/',
	CamThumbExt: '.jpg',

	Map: {
		//  Leaflet map will center on this point, at the provided zoom level
		//  https://leafletjs.com/reference-1.4.0.html#map-setview
		DefaultLat: 39.76632525654491,
		DefaultLon: -86.15615844726564,
		DefaultZoom: 12,
		MinZoom: 7,
		MaxZoom: 18,
		SpiderifyDistancePx: 10,
		SpiderifyLegThicknessPx: 3,
		SpiderifyLegColorNormal: 'rgba(var(--brand-grey-dark),0.6)',
		SpiderifyLegColorFocus: 'rgba(var(--brand-grey-dark),1)',
		icons: {
			CCTV: {
				message: 'img/icon-camera-fill-solid-2.svg',
			},
		},
	},
	Table: {
		pagination: [5, 15, 50, 100],
		defaultColumns: [
			'cameraNumber',
			'description',
			'name',
			...(USER_CAN_TOGGLE_CAMERA_FEED ? ['status'] : []),
		] as typeof cctvColumns[number][],
	},
	WEB_APPLICATION_SOURCE: 'INDOT_carsx',
	FEED_SERVICE_SOURCE: 'INDOT_Feed',
	ADMIN_ROLE_ID: 1,
};

export const ConfigCCTVApi = {
	//  CAMERAS
	getCamerasEndpoint(): string {
		return 'devices';
	},
	getRetrieveDeviceEndpoint(deviceId: number): string {
		return `devices/${deviceId}`;
	},
	getMatchCameraNumberEndpoint(cameraNumber: number): string {
		return `devices/validate/number/${cameraNumber}`;
	},
	getMatchCameraIdEndpoint(friendlyName: string): string {
		return `devices/validate/friendlyName/${friendlyName}`;
	},
	getSaveDeviceEndpoint(): string {
		return 'devices'; // POST
	},
	getDeleteDeviceEndpoint(deviceId: number): string {
		return `devices/${deviceId}`; // DELETE
	},
	getEndpointTimeoutMs(): number {
		return 60 * MILLISECONDS_PER_SECOND;
	},
	getCameraRolesEndpoint(): string {
		return `devices/roles`;
	}, //  GET
	//  MONSTREAM CONTROLLERS
	getMonitorsEndpoint(): string {
		return `controllers`;
	}, //  GET
	getSetMonitorEndpoint(name: string): string {
		return `controllers/${name}/cameras`;
	}, //  POST
	getSetMonitorLayoutEndpoint(name: string): string {
		return `controllers/${name}/layout`;
	}, //  POST
	getCameraThumbUrl(id: string | number): string {
		return `cameras/${id}/stillshot`;
	},
	setCameraOnline: (id: string | number) => `cameras/${id}/online`,
	setCameraOffline: (id: string | number) => `cameras/${id}/offline`,
	setAllCamerasOnline: () => 'cameras/onlineAll',
	setAllCamerasOffline: () => 'cameras/offlineAll',
	endpointTimeoutMs: 60 * MILLISECONDS_PER_SECOND,
	camerasPollingRate: 60 * MILLISECONDS_PER_SECOND,
	monitorsPollingRate: 2 * MILLISECONDS_PER_SECOND,
};

export const getCameraTitle = (cameraDTO: CameraDto | null) =>
	!cameraDTO ? `--` : `#${cameraDTO.cameraNumber} ${cameraDTO.description} (${cameraDTO.name})`;

export default ConfigCCTV;
