import { css } from 'lit';
import scss from '../../utils/scssAsCss';

export default css`
.section-label {
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: rgb(var(--brand-grey-dark));
}

.section-sublabel {
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-style: italic;
  text-transform: uppercase;
  color: rgb(var(--brand-grey));
  margin-bottom: calc(var(--ggutter) / 4);
}

.field-label {
  font-size: 1.3rem;
  font-style: italic;
  color: rgb(var(--brand-grey-dark));
}
.field-label sup {
  text-indent: 1ex;
  font-size: 1rem;
  vertical-align: super;
}

.field-box {
  padding: calc(var(--ggutter) / 2);
  border: 1px solid rgb(var(--brand-grey-dimmer));
  border-radius: 3px;
  /* box-shadow: 0px 0px 3px rgba(43, 128, 228, 0.1); */
  line-height: 1.5;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  background: rgb(var(--brand-grey-lightest));
  transition: all 0.2s ease-in-out;
}

.field-box:hover,
.field-box:focus {
  background: rgb(var(--brand-focus-light));
}

.field-error-input:focus {
  border-color: rgb(var(--brand-status-danger));
}

.field-error-msg {
  font-size: 1.3rem;
  line-height: 2.1rem;
  background-color: rgb(var(--brand-status-danger));
  color: #fff;
  bottom: calc(var(--ggutter) * -0.75);
  padding: calc(var(--ggutter) / 4) calc(var(--ggutter) / 2);
}

.control-button {
  padding: calc(var(--ggutter) * 0.75) var(--ggutter);
  border-radius: 3px;
  border: 0;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
}
.control-button:disabled, .control-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.control-button.primary-button, .control-button.secondary-button {
  font-size: 1.6rem;
  line-height: 1;
}
.control-button.primary-button {
  text-transform: uppercase;
  color: white;
  background: rgb(var(--brand-primary));
  border: 1px solid rgb(var(--brand-primary));
}
.control-button.secondary-button {
  color: rgb(var(--brand-primary));
  border: 1px solid rgb(var(--brand-primary));
  background: none;
}
.control-button.inline-form-button {
  font-size: 1.3rem;
  color: white;
  background: rgb(var(--brand-secondary));
}
.control-button.lg {
  padding: var(--ggutter);
}
.control-button.xl {
  padding: calc(var(--ggutter) * 1.5);
}
.control-button.full {
  width: 100%;
  flex: 1;
  text-align: center;
  justify-content: center;
}
.control-button.link {
  border: none;
}
.control-button:hover {
  opacity: 0.7;
}

.ghost-box {
  border: 0.5px solid rgb(var(--brand-grey-darkest), 0.1);
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  background: white;
}

.box-shadow-1 {
  box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.05) 0px 4px 4px 0px, rgba(0, 0, 0, 0.05) 0px 7px 7px 0px;
}
`;
