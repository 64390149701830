import { css } from 'lit';
import { UserPermissions, UserRoles } from '../../typings/api';
import store from './redux-store';

const adminPermissionExcemptions = [
	UserPermissions.REM_CAN_CREATE_EVENTS_LIMITED,
	UserPermissions.CCTV_READONLY,
	UserPermissions.DMS_READONLY,
	UserPermissions.REM_READONLY,
	UserPermissions.HH_READONLY,
];

export const userIsAdmin = (roles: UserRoles[]): boolean => roles.includes(UserRoles.admin);

export const userIsGuest = (roles: UserRoles[]): boolean => roles.includes(UserRoles.guest);

export const userIsHoosierHelper = (): boolean => {
	const { authority } = store.getState().user;

	return authority?.roles.includes(UserRoles['hoosier-helper']) ?? false;
};

export const userHasRole = (roles: UserRoles[], role: UserRoles): boolean => roles.includes(role);

export const userHasPermission = (permission?: UserPermissions) => {
	if (permission === undefined) {
		return false;
	}

	const { authority } = store.getState().user;

	if (authority === undefined || authority.permissions === null) {
		console.warn('missing user permissions definitions');
		return false;
	}
	//	easy permission testing:
	//	authority.permissions.push(UserPermissions.REM_READONLY);
	//	just don't forget to remove it when finished!
	if (adminPermissionExcemptions.includes(permission)) {
		return authority.permissions.includes(permission);
	}
	return (
		(authority.roles && userIsAdmin(authority.roles)) || authority.permissions.includes(permission)
	);
};

export default userHasPermission;
