import { css } from 'lit';
import { bind } from 'bind-decorator';
import { CSSResultArray, LitElement, TemplateResult, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { PageConfig } from '../../config/ConfigCARSx';
import userHasPermission from '../../redux/user-permissions';
import containsTargetPenetrate from '../../utils/contains-penetrate';
import scss from '../../utils/scssAsCss';
import bbuttonbaseScss from '../css/lib/bbuttonbase.scss';
import sharedScss from '../css/shared.scss';

@customElement('nav-menu-item')
export default class NavMenuItem extends LitElement {
	@property({ reflect: true, type: Boolean }) public active = false;

	@property({ type: Array }) public subpages?: Partial<PageConfig>[] = undefined;

	@property({ type: String }) public icon = '';

	@property({ type: String }) public href?: string;

	@property({ type: String }) public label?: string;

	@property({ type: Boolean }) public linksRight = false;

	@property({ type: Boolean, reflect: true }) private detailsOpen = false;

	private dropDownRef: Ref<Element> = createRef();

	public static override get styles(): CSSResultArray {
		return [
			bbuttonbaseScss,
			sharedScss,
			css`
:host {
  height: 100%;
  width: 100%;
  display: flex;
}

:host([linksRight]) {
  justify-content: flex-end;
}

:host(:hover),
:host([detailsOpen]) {
  background-color: rgba(var(--brand-primary-darkest), 0.5);
}

.bbuttonbase span {
  display: flex;
}

a {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0px var(--ggutter);
  text-decoration: inherit;
  color: inherit;
}
a > div {
  flex: 0 0 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-top: calc(var(--ggutter) * 0.75);
  border-radius: 50%;
  background-color: rgb(var(--brand-grey-dim));
}
a > div img {
  display: block;
}
a span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0;
  flex: 0 1 100%;
  margin-top: 4px;
}

button.active a {
  background-color: rgba(var(--brand-primary-darkest), 0.5);
  color: rgb(var(--brand-secondary));
}
button.active a > div {
  background-color: rgb(var(--brand-secondary));
}

button.item-container {
  height: 100%;
  position: relative;
}
button.item-container * {
  pointer-events: none;
}
button.item-container img, button.item-container .caret {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}
button.item-container .caret {
  margin-left: 1ch;
  width: 14px;
  height: 14px;
  fill: rgb(var(--brand-grey-dim));
  transform: rotate(-90deg);
}
button.item-container .caret.active {
  fill: rgb(var(--brand-secondary));
}
button.item-container p {
  display: inline-block;
  vertical-align: middle;
}

.rotate {
  transform: rotate(0deg) !important;
}

.dropdownLinks {
  display: none;
  flex-direction: column;
  position: absolute;
  align-items: start;
  border: 1px solid rgb(var(--brand-grey-dimmer));
  background-color: white;
  box-shadow: 0px 5px 8px 3px rgba(var(--brand-grey-darkest), 0.2);
  /* max-width: 18ch; */
  z-index: 1025;
  white-space: nowrap;
}

.dropdownLinks[open] {
  display: flex;
}
.dropdownLinks[open] * {
  pointer-events: all;
}

.dropdownLinks[right] {
  right: 0;
}

.dropdownItem {
  color: black;
  margin: 0rem !important;
  width: 100%;
}
.dropdownItem a {
  height: 100%;
  display: block;
  padding: calc(var(--ggutter) / 2) var(--ggutter) !important;
  align-items: flex-start;
  text-align: left;
  font: inherit;
}
.dropdownItem a[active] * {
  pointer-events: none;
}

.dropdown-not-link {
  padding: calc(var(--ggutter) / 2) var(--ggutter) !important;
  align-items: flex-start;
  text-align: left;
  color: rgb(var(--brand-grey-dark)) !important;
  border-bottom: 1px solid rgb(var(--brand-grey-dimmer));
  white-space: initial;
  cursor: default;
}

.dropdownItem > a > span {
  align-self: flex-start;
}

@media (max-width: 999px) {
  button.item-container {
    display: block;
    width: 100%;
    float: none;
    clear: both;
    margin: 0px;
    text-align: unset;
  }
  button.item-container a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px var(--ggutter) var(--ggutter);
  }
  button.item-container a > div > img {
    display: inline-block;
  }
  button.item-container a span {
    margin-top: 10px;
    display: inline-block;
    text-indent: 1em;
  }
}
.item-container:focus {
  outline: 2px solid rgb(var(--brand-secondary));
}

li.dropdownItem:focus-within {
  outline: 2px solid rgb(var(--brand-focus));
  z-index: 5;
}

.label-link {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: initial;
}
@media (min-width: 1000px) {
  .label-link {
    max-width: 150px;
  }
}

@media (min-width: 1000px) {
  :host([linksRight]) .label-link {
    text-align: right;
  }
}
`,
		];
	}

	fireClose(): void {
		this.dispatchEvent(new CustomEvent('closeNav'));
	}

	private onIconClick(e: MouseEvent): void {
		const targetElement = e.currentTarget as HTMLAnchorElement;
		const { href } = targetElement.dataset.href ? targetElement.dataset : targetElement;

		if (href && targetElement.tagName !== 'A') {
			this.dispatchEvent(new CustomEvent('linkClick', { detail: href }));
			this.fireClose();
		} else {
			if (this.detailsOpen) {
				this.fireClose();
			}
			this.detailsOpen = !this.detailsOpen;
		}
	}

	public override render(): TemplateResult {
		if (!this.label) {
			return html``;
		}

		return html`<button
			tabindex="0"
			class=${classMap({
				'bbuttonbase': true,
				'active': this.active,
				'item-container': true,
			})}
			@click=${this.onIconClick}
			data-href="${this.subpages === undefined || this.subpages.length === 0
				? ifDefined(this.href)
				: undefined}"
		>
			<a title="${ifDefined(this.label)}">
				<div part="bg">
					<img part="img" src="${this.icon}" width="24" height="24" />
				</div>
				<span part="label">
					<p class="label-link">${this.label}</p>
					${this.subpages?.length
						? html`<svg
								role="presentation"
								part="caret"
								class=${classMap({
									rotate: this.detailsOpen,
									active: this.active,
									caret: true,
								})}
						  >
								<use href="img/sprites.svg#caret"></use>
						  </svg>`
						: html``}
				</span>
			</a>
			${this.subpages && this.subpages?.length
				? html` <div
						${ref(this.dropDownRef)}
						?open=${this.detailsOpen}
						id="${this.label}Dropdown"
						?right=${this.linksRight === true}
						class="dropdownLinks"
				  >
						${this.subpages.map(this.dropdownItem)}
				  </div>`
				: html``}
		</button>`;
	}

	dropdownItem = (page: Partial<PageConfig>) => {
		if (page.active === false) {
			return undefined;
		}

		if (page.permission === undefined || userHasPermission(page.permission) === true) {
			if (page.route !== undefined) {
				return html`
					<li class="dropdownItem">
						<a class="btn-link" ?active=${this.detailsOpen} href="${ifDefined(page.route)}">
							${page.pageTitle}
						</a>
					</li>
				`;
			}
			return html` <li class="dropdownItem  dropdown-not-link">${page.pageTitle}</li> `;
		}
		return undefined;
	};

	@bind
	private onGlobalMouseup(e: MouseEvent): void {
		if (this.detailsOpen === true && !containsTargetPenetrate(this.dropDownRef.value as Node, e)) {
			this.detailsOpen = false;
		}
	}

	onBlur(): void {
		this.detailsOpen = false;
	}

	public override connectedCallback(): void {
		window.addEventListener('mouseup', this.onGlobalMouseup);
		this.addEventListener('blur', this.onBlur);
		super.connectedCallback();
	}

	public override disconnectedCallback(): void {
		window.removeEventListener('mouseup', this.onGlobalMouseup);
		this.removeEventListener('blur', this.onBlur);
		super.disconnectedCallback();
	}
}
