import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import ConfigCARSx, { APIConfig, DebuggingConfig } from '@/config/ConfigCARSx';
import { AuthorityDto, UserPermissions, UserRoles } from '../../typings/api';
import { ConfigLoginApi } from '../config/ConfigLogin';
import { AppSection, HttpStatusCode, MILLISECONDS_PER_HOUR, MetricsView } from '../constants';
import Authorization from '../rest/Authorization';
import { getAPIHeaders } from './APIRequest';
import store, { RootState, ThunkActionRoot, ThunkDispatchRoot } from './redux-store';
import userHasPermission from './user-permissions';
import { showMainBanner } from './redux-ui';
import { NotificationErrorType } from '../../typings/shared-types';
import hasOwnProperty from '../utils/has-own-property';
import { navigate } from './redux-routing';

export enum UserActionType {
	GET_USER = 'GET_USER',
	SET_USER = 'SET_USER',
	CLEAR_USER = 'CLEAR_USER',
	GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS',
	SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS',
}

export type UserState = {
	authority?: AuthorityDto;
};

export const USER_STATE_INITIAL: UserState = {
	authority: undefined,
};

interface GetUser extends Action<typeof UserActionType.GET_USER> {
	name: string;
	password: string;
}

type ClearUser = Action<typeof UserActionType.CLEAR_USER>;

type GetUserPermissions = Action<typeof UserActionType.GET_USER_PERMISSIONS>;

interface SetUserPermissions extends Action<typeof UserActionType.SET_USER_PERMISSIONS> {
	authority: AuthorityDto;
}

export type UserAction = GetUser | ClearUser | GetUserPermissions | SetUserPermissions;

export function setJwt(loginResult: AuthorityDto) {
	Authorization.JWT = `${loginResult.tokenType} ${loginResult.accessToken}`;
}

let permissionRefreshInterval: ReturnType<typeof setInterval> | undefined;

function onVisibilityChangeCheckToken() {
	if (!document.hidden) {
		// Check if session is expired whenever the user returns to the page
		void store.dispatch(getUserPermissions());
	}
}

function refreshAuthToken(dispatch: ThunkDispatchRoot): void {
	permissionRefreshInterval && clearInterval(permissionRefreshInterval);
	permissionRefreshInterval = setInterval(() => {
		void dispatch(getUserPermissions());
	}, MILLISECONDS_PER_HOUR);

	document.addEventListener('visibilitychange', onVisibilityChangeCheckToken);
}

export const stopRefreshingAuthToken = (): void => {
	permissionRefreshInterval && clearInterval(permissionRefreshInterval);

	document.removeEventListener('visibilitychange', onVisibilityChangeCheckToken);
};

export const getUserPermissions =
	(): ThunkActionRoot<Promise<boolean>> =>
	async (dispatch): Promise<boolean> => {
		dispatch({ type: UserActionType.GET_USER_PERMISSIONS });

		const url = new URL(ConfigLoginApi.getUserPermissions(), APIConfig.endpointURLBase).href;

		try {
			const response = await fetch(
				new Request(url, {
					method: HTTPMethod.GET,
					headers: new Headers({ ...getAPIHeaders() }),
				}),
			);

			if (response.status === HttpStatusCode.UNAUTHORIZED) {
				// User may have been disabled/deleted
				dispatch(
					showMainBanner(NotificationErrorType.WARNING, {
						title: `User session ended. Please login again.`,
					}),
				);
				Authorization.logout();
				await dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));

				return false;
			}

			const authority: AuthorityDto = (await response?.json()) as AuthorityDto;

			if (!response?.ok || authority.accessToken === null) {
				dispatch(
					showMainBanner(
						NotificationErrorType.WARNING,
						{
							title: `User session ended. Please login again.`,
						},
						5000,
					),
				);
				Authorization.logout();
				await dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
				return false;
			}

			// TODO Just have the server return all permissions for admin
			if (authority.permissions.length === 0 && !authority.roles.includes(UserRoles.admin)) {
				dispatch(
					showMainBanner(
						NotificationErrorType.ERROR,
						{
							title: `Application error`,
							messages: [`User "${authority.name}" has no permissions.`],
						},
						5000,
					),
				);
				Authorization.logout();
				await dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
				return false;
			}

			setJwt(authority);
			dispatch({ type: UserActionType.SET_USER_PERMISSIONS, authority });
			refreshAuthToken(dispatch);

			return true;
		} catch (error) {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `Application or network error`,
						messages: [`"${url}" failed to return a valid response.`],
					},
					5000,
				),
			);
			Authorization.logout();
			await dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
			DebuggingConfig.showConsoleLogs && console.error(`error parsing user permissions:`, error);
			return false;
		}
	};

export const clearUser = (): ClearUser => ({
	type: UserActionType.CLEAR_USER,
});

export enum LoginResult {
	Success,
	InvalidCredentials,
	TooManyFailedAttempts,
	ServerError,
}

export const getUser =
	(name: string, password: string): ThunkActionRoot<Promise<LoginResult>> =>
	async (dispatch) => {
		dispatch({ type: UserActionType.GET_USER });

		try {
			const url = new URL(ConfigLoginApi.loginUser(), APIConfig.endpointURLBase).href;
			const response = await fetch(
				new Request(url, {
					method: HTTPMethod.POST,
					headers: new Headers({ ...getAPIHeaders() }),
					body: JSON.stringify({
						name,
						password,
					}),
				}),
			);
			// http status code locked
			if (response.status === HttpStatusCode.UNAUTHORIZED) {
				dispatch(
					showMainBanner(NotificationErrorType.ERROR, {
						title: `Could not login with the provided username and password`,
					}),
				);
				return LoginResult.InvalidCredentials;
			}
			if (response.status === HttpStatusCode.I_AM_A_TEAPOT) {
				dispatch(
					showMainBanner(NotificationErrorType.ERROR, {
						title: `Too many failed login attempts. Please try again later`,
					}),
				);
				return LoginResult.TooManyFailedAttempts;
			}
			const authority: AuthorityDto = (await response?.json()) as AuthorityDto;

			if (!response.ok || authority.accessToken === null) {
				DebuggingConfig.showConsoleLogs &&
					console.error(
						`error authenticating user "${name}" using data provided, status code: "${response?.status}`,
					);

				return LoginResult.ServerError;
			}

			if (
				authority?.permissions.length === 0 &&
				// TODO Just have the server return all permissions for admin
				!authority?.roles.includes(UserRoles.admin)
			) {
				dispatch(
					showMainBanner(NotificationErrorType.ERROR, {
						title: `Application error`,
						messages: [`User "${authority.name}" has no permissions.`],
					}),
				);
				return LoginResult.ServerError;
			}
			//	if accessToken is not null, then the user is authenticated
			//	and we can rely on tokenType and accessToken being defined
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			setJwt(authority);
			dispatch({ type: UserActionType.SET_USER_PERMISSIONS, authority });
			refreshAuthToken(dispatch);

			return LoginResult.Success;
		} catch (error) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: 'Could not parse login response from server',
				}),
			);
			DebuggingConfig.showConsoleLogs &&
				console.error(`error parsing server response while logging in "${name}":`, error);
			return LoginResult.ServerError;
		}
	};

export const UserReducer = (
	state: UserState = USER_STATE_INITIAL,
	action: UserAction | undefined = undefined,
): UserState => {
	if (action === undefined) {
		return state;
	}
	switch (action.type) {
		case UserActionType.SET_USER_PERMISSIONS:
			return {
				...state,
				authority: action.authority,
			};
		case UserActionType.GET_USER_PERMISSIONS:
		case UserActionType.GET_USER:
		case UserActionType.CLEAR_USER:
		//	intentional fallthrough for unhandled action
		default:
			return state;
	}
};

// SELECTORS

export const selectShowReadOnly = (state: RootState): boolean =>
	(state.routing.page === AppSection.CCTV && userHasPermission(UserPermissions.CCTV_READONLY)) ||
	(state.routing.page === AppSection.DMS && userHasPermission(UserPermissions.DMS_READONLY)) ||
	(state.routing.page === AppSection.REM &&
		(state.rem.readOnlyMode === true || userHasPermission(UserPermissions.REM_READONLY))) ||
	(state.routing.page === AppSection.HH && userHasPermission(UserPermissions.HH_READONLY)) ||
	(state.routing.page === AppSection.METRICS && state.routing.view === MetricsView.event);

export const selectLandingPageForUser = (): string => {
	const landingPage: string = ConfigCARSx.defaultPage;

	if (
		ConfigCARSx.Pages[landingPage as AppSection].active &&
		userHasPermission(ConfigCARSx.Pages[landingPage as AppSection].permission)
	) {
		return `/${landingPage}`;
	}
	// find first page user has permission for, default to config
	for (const page of Object.values(ConfigCARSx.Pages)) {
		if (page.active && hasOwnProperty(page, 'permission') && userHasPermission(page.permission)) {
			return page.route;
		}
	}

	return `/${landingPage}`;
};
